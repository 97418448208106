<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <h2 class="brand-text text-primary ml-1">
        BrandMe
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{$t('error.privateTitle')}}
        </h2>
        <p class="mb-2">
          {{$t('error.privateContent')}}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          @click="loginRoute()"
        >
          {{$t('error.backhome')}}
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="downImg"
          alt="Error page"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { BLink, BButton, BImg } from 'bootstrap-vue'
export default {
  components: {
    BLink,
    BButton,
    BImg
  },
  data () {
    return {
      downImg: require('@/assets/images/pages/error.svg')
    }
  },
  methods: {
    loginRoute () {
      this.$router.push('/dashboard')
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
